
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/destination/[state]/[destination]/things-to-do/[category]",
      function () {
        return require("private-next-pages/destination/[state]/[destination]/things-to-do/[category].ts");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/destination/[state]/[destination]/things-to-do/[category]"])
      });
    }
  